import React, { useEffect } from "react";
import { graphql } from "gatsby";

import Hero from "../../components/Hero/hero";
import HeroAlt from "../../components/Hero/heroAlt";
import SideImage from "../../components/Section/sideImage";
import BgImage from "../../components/Section/bgImage";
import ImageBig from "../../components/Section/imageBig";
import ImageSmall from "../../components/Section/imageSmall";
import Image from "../../components/Section/image";
import Centered from "../../components/Section/centered";
import CenteredSmall from "../../components/Section/centeredSmall";
import Html from "../../components/Section/html";
import Card from "../../components/Section/card";

const Cleaner = () => {
  return <div className="full-width" />;
};

const HomepageContent = (props) => {
  const _page = props.graphCmsPage;
  const _blocks = [];
  _page.partial.forEach((block) => {
    if (block.slug === "block-hero") {
      _blocks.push(<Hero key={block.id} {...block} id={block.slug} />);
    } else if (block.slug === "block-agility") {
      _blocks.push(<HeroAlt key={block.id} {...block} id={block.slug} />);
    } else {
      switch (block.blockType) {
        case "CARD_1":
          block.cleanerBefore &&
            _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
          _blocks.push(
            <Card key={block.id} {...block} id={block.id} flex={1} />
          );
          break;
        case "CARD_2":
          block.cleanerBefore &&
            _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
          _blocks.push(
            <Card key={block.id} {...block} id={block.id} flex={2} />
          );
          break;
        case "CARD_3":
          _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
          _blocks.push(
            <Card key={block.id} {...block} id={block.id} flex={3} />
          );
          _blocks.push(<Cleaner key={block.id + "_cleaner_after"} />);
          break;
        case "HTML":
          _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
          _blocks.push(<Html key={block.id} {...block} id={block.slug} />);
          _blocks.push(<Cleaner key={block.id + "_cleaner_after"} />);
          break;
        case "IMAGE":
          _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
          _blocks.push(<Image key={block.id} {...block} id={block.slug} />);
          _blocks.push(<Cleaner key={block.id + "_cleaner_after"} />);
          break;
        case "IMAGE_BIG":
          _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
          _blocks.push(<ImageBig key={block.id} {...block} id={block.id} />);
          _blocks.push(<Cleaner key={block.id + "_cleaner_after"} />);
          break;
        case "IMAGE_SMALL":
          _blocks.push(<ImageSmall key={block.id} {...block} id={block.id} />);
          break;
        case "CENTERED":
          _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
          _blocks.push(<Centered key={block.id} {...block} id={block.slug} />);
          _blocks.push(<Cleaner key={block.id + "_cleaner_after"} />);
          break;
        case "CENTERED_SMALL":
          _blocks.push(
            <CenteredSmall key={block.id} {...block} id={block.id} />
          );
          break;
        case "BGIMAGE":
          _blocks.push(<BgImage key={block.id} {...block} id={block.slug} />);
          break;
        case "SIDEIMAGE":
        default:
          _blocks.push(<SideImage key={block.id} {...block} id={block.slug} />);
          break;
      }
    }
    //console.log(block)
  });
  return _blocks;
};
const HomePage = ({ data }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <HomepageContent {...data} />;
};
export const query = graphql`
  query {
    graphCmsPage(slug: { eq: "homepage" }, locale: { eq: en }) {
      id
      body {
        html
      }
      illustr {
        gatsbyImageData
      }
      leadLine
      partial {
        ... on GraphCMS_Block {
          id
          title
          leadLine
          description
          buttonText
          blockType
          slug
          link
          illustr {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
          icon {
            localFile {
              publicURL
            }
          }
          colors {
            hex
          }
          download
          leadLine
          locale
          markup {
            html
          }
          cleanerBefore
        }
      }
      slug
      title
      category
      description
      icon {
        gatsbyImageData
      }
    }
  }
`;

export default HomePage;
